import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { OneSignal } from "onesignal-ngx";
import { environment } from "../../../../environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { isPlatformBrowser } from "@angular/common";
import { NotificationService } from "./notification.service";
import { take } from "rxjs";
import { Store } from "@ngrx/store";
import { UnreadNotificationState } from "../../ngrx/reducers/unread-notification.reducer";
import { setUnreadNotification } from "../../ngrx/actions/unread-notification.action";
import { ENotificationRelatedObjectType } from "../../enums/notification-related-object-type.enum";

@Injectable()
export class PushNotificationService {
  isInit = false;
  subscribedUserId?: number;

  constructor(
    private oneSignal: OneSignal,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private store: Store<UnreadNotificationState>,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
  }

  init(userId: number) {
    if (isPlatformBrowser(this.platformId)) {

      if (!this.isInit) {
        this.oneSignal.init({
          appId: environment.oneSignalAppId,
          welcomeNotification: undefined,
          promptOptions: {
            autoPrompt: false,
            autoResubscribe: true,
            autoRegister: false,
            actionMessage: this.translateService.instant(`notification.prompt_message`),
            acceptButtonText: this.translateService.instant(`notification.accept`),
            cancelButtonText: this.translateService.instant(`notification.cancel`),
          },
        }).then(r => {
          this.isInit = true;

          if (this.oneSignal.User.PushSubscription.id) {
            this.subscribeNotificationId(userId);
          } else {
            this.oneSignal.Slidedown.promptPush({
              force: true,
            }).then(cr => {
            });
          }


          this.oneSignal.User.PushSubscription.addEventListener('change', (cr) => {
            this.subscribeNotificationId(userId);
          });

          this.oneSignal.Notifications.addEventListener('click', (cr) => {
            const additionalData = cr?.notification?.additionalData as
              { relatedObjectType?: ENotificationRelatedObjectType | null, relatedObjectId?: number | null };

            this.notificationService.routeNotification(additionalData?.relatedObjectType, additionalData?.relatedObjectId);
          });

          this.oneSignal.Notifications.addEventListener('foregroundWillDisplay', (cr) => {

            this.notificationService.getUnreadNotificationCount().pipe(take(1))
              .subscribe(r => {
                this.store.dispatch(setUnreadNotification(r.data));
              });

          });

          // this.oneSignal.Notifications.addEventListener('dismiss', (cr) => {
          //   console.log('Notification dismiss');
          //   console.log(cr);
          // });
        });
      } else if (this.subscribedUserId !== userId) {
        if (this.oneSignal.User.PushSubscription.id) {
          this.subscribeNotificationId(userId);
        }
      } else {
        // console.log('Same user already subscribed');
      }


    }

  }

  subscribeNotificationId(userId: number) {
    this.subscribedUserId = userId;

    this.notificationService.subscribeToken(this.oneSignal.User.PushSubscription.id as string)
      .toPromise().then(r => {
    });
  }

}
