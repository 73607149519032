import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class ReminderService {
  private apiPrefix = '/client/reminders';

  constructor(
    private api: ApiService,
  ) {
  }

  getReminders(date?: Date) {
    return this.api.get(`${this.apiPrefix}`, date ? `?date=${date.toString()}` : ``);
  }

  createReminder(body: any) {
    return this.api.post(`${this.apiPrefix}`, body);
  }

  updateReminder(id: number, body: { message: string, date?: Date }) {
    return this.api.patch(`${this.apiPrefix}/${id}`, body);
  }

  deleteReminder(id: number) {
    return this.api.delete(`${this.apiPrefix}/${id}`);
  }
}
